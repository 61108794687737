import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import getCurrentUser from "../../utils/getCurrentUser";
import "./Reqtutor.css";

function Reqtutor() {
  const currentUser = getCurrentUser();
  const queryClient = useQueryClient();

  // Fetching all tutor applications
  const { isLoading, error, data } = useQuery({
    queryKey: ["myReqtutors"],
    queryFn: () => newRequest.get("/reqtutors").then((res) => res.data),
  });

  // Mutation for deleting a tutor request
  const deleteMutation = useMutation({
    mutationFn: (id) => newRequest.delete(`/reqtutors/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["myReqtutors"]);
    },
    onError: (error) => {
      console.error("Error deleting Reqtutor:", error);
    },
  });

  // Mutation for approving and creating a gig
  const approveMutation = useMutation({
    mutationFn: (reqtutor) => newRequest.post("/gigs", reqtutor),
    onSuccess: (reqtutor) => {
      console.log("Tutor approved and saved as a gig:", reqtutor);
      deleteMutation.mutate(reqtutor._id);
    },
    onError: (error) => {
      console.error("Error approving tutor:", error);
    },
  });

  const handleDelete = async (id) => {
    if (!currentUser.admin) {
      alert("You are not authorized to delete this application.");
      return;
    }
    try {
      await deleteMutation.mutateAsync(id);
    } catch (error) {
      console.error("Error deleting Reqtutor:", error);
    }
  };

  const handleApprove = async (reqtutor) => {
    if (!currentUser.admin) {
      alert("You are not authorized to approve this application.");
      return;
    }
    try {
      await approveMutation.mutateAsync(reqtutor);
    } catch (error) {
      console.error("Error approving tutor:", error);
    }
  };

  return (
    <div className="myGigs">
      {isLoading ? (
        "Loading..."
      ) : error ? (
        "An error occurred while fetching data."
      ) : (
        <div className="container">
          <div className="title">
            <h1>Applications</h1>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Price</th>
                <th>Qualification</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((reqtutor) => (
                <tr key={reqtutor._id}>
                  <td>{reqtutor.title}</td>
                  <td>{reqtutor.shortTitle}</td>
                  <td>{reqtutor.price}</td>
                  <td>{reqtutor.qualification}</td>
                  <td>
                    <div className="btn">
                      <button
                        className="approve"
                        onClick={() => handleApprove(reqtutor)}
                      >
                        Approve
                      </button>
                      <img
                        className="delete"
                        src="./img/delete.png"
                        alt="Delete"
                        onClick={() => handleDelete(reqtutor._id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Reqtutor;
