import React from "react";
import "./Home.css";
import Featured from "../../components/featured/Featured";
import Footer from "../../components/footer/Footer";
function Home() {
  return (
    <div className="home">
      <Featured />
      <Footer />
    </div>
  );
}

export default Home;
