import React from "react";
import { Link } from "react-router-dom";
import "./MyGigs.css";
import getCurrentUser from "../../utils/getCurrentUser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";

function MyGigs() {
  const currentUser = getCurrentUser();
  const queryClient = useQueryClient();

  // Fetch gigs data
  const { isLoading, error, data } = useQuery({
    queryKey: ["myGigs"],
    queryFn: () => newRequest.get("/gigs").then((res) => res.data),
  });

  // Set up the mutation
  const mutation = useMutation({
    mutationFn: (id) => newRequest.delete(`/gigs/${id}`),  // The mutation function
    onSuccess: () => {
      queryClient.invalidateQueries(["myGigs"]);  // Invalidate the 'myGigs' query to refetch data
    },
    onError: (error) => {
      console.error("Error deleting gig:", error);
    },
  });

  const handleDelete = (id) => {
    if (!currentUser.admin) {
      alert("You are not authorized to delete this gig.");
      return;
    }
    mutation.mutate(id);  // Trigger the mutation
  };

  // Sort gigs alphabetically by title
  const sortedGigs = data?.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div className="myGigs">
      <div className="container">
        <div className="header">
          <h1>Teachers</h1>
          {currentUser.admin && (
            <Link to="/add">
              <button className="add-button">Add New Teacher</button>
            </Link>
          )}
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>An error occurred while fetching data.</p>
        ) : (
          <div className="cards">
  {sortedGigs?.map((gig) => (
    <div className="card" key={gig._id}>
      <div className="card-content">
        <h3>{gig.title}</h3>
        <p><strong>ID:</strong> {gig.gti}</p>
        <p><strong>Category:</strong> {gig.cat}</p>
        <p><strong>Short Description:</strong> {gig.shortDesc}</p>
        <p><strong>Description:</strong> {gig.desc}</p>
        <p><strong>Skills/Subjects:</strong> {gig.features.join(", ")}</p>
        <p><strong>Qualification:</strong> {gig.qualification}</p>
        <p><strong>Work Experience:</strong> {gig.workExperience} years</p>
        <p><strong>Languages Known:</strong> {gig.languagesKnown.join(", ")}</p>
        <p><strong>Current Occupation:</strong> {gig.currentOccupation}</p>
        <p><strong>Specialities:</strong> {gig.specialities.join(", ")}</p>
        <p><strong>Major Strengths:</strong> {gig.majorStrengths.join(", ")}</p>
        <p><strong>Phone Number:</strong> {gig.primaryphone}</p>
        <p><strong>Secondary Phone Number:</strong> {gig.secondaryphone}</p>
        <p><strong>Email:</strong> {gig.email}</p>
        <p><strong>Avg. Price:</strong> ₹{gig.price} per hour</p>
      </div>
      {currentUser.admin && (
        <button
          className="delete-button"
          onClick={() => handleDelete(gig._id)} // Delete the gig
        >
          Delete
        </button>
      )}
    </div>
  ))}
</div>

        )}
      </div>
    </div>
  );
}

export default MyGigs;
