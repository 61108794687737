import React from 'react';
import newRequest from "../../utils/newRequest";
import "./Newuser.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const Newuser = () => {
  const queryClient = useQueryClient();

  const { data: newUsers = [], isLoading, isError } = useQuery({
    queryKey: ["getAllNewUsers"],
    queryFn: async () => {
      const response = await newRequest.get("/newuser/getAllNewUsers");
      return response.data || [];
    },
  });

  const mutation = useMutation({
    mutationFn: (id) => newRequest.delete(`/newuser/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["getAllNewUsers"]);
    },
  });

  const handleDelete = (id) => {
    mutation.mutate(id);
  };

  const handleApprove = (user) => {
    const userData = {
      username: user.username,
      email: user.email,
      password: user.password,
      admin: user.admin,
      phone: user.phone,
    };

    newRequest.post("/auth/register", userData)
      .then(() => {
        handleDelete(user._id);
      })
      .catch((error) => {
        console.error("Error approving user:", error);
      });
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching new users.</div>;

  return (
    <div className='myGigs'>
      <div className='container'>
        <div className="title">
          <h1>New Employee</h1>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Admin</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {newUsers.map((user) => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td>{user.phone}</td>
                <td>{user.admin ? "Admin" : "Not Admin"}</td>
                <td>
                  <div className="btn">
                    <button className="approve" onClick={() => handleApprove(user)}>Approve</button>
                    <img
                      className="delete"
                      src="./img/delete.png"
                      alt="Delete"
                      onClick={() => handleDelete(user._id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Newuser;
