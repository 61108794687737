import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Featured.css";

export default function Featured() {
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const handleSubmit = () => {
    if (!currentUser) {
      window.alert("You must be logged in to perform a search.");
      return;
    }

    if (!input.trim()) {
      window.alert("Please enter a search query.");
      return;
    }

    navigate(`/gigs?search=${encodeURIComponent(input)}`);
  };

  return (
    <div className="featured">
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div className="container">
        <div className="left">
          <h1>
            Find online Teachers <br />and Home tutor
          </h1>
          <div className="search">
            <div className="searchInput">
              <img src="./img/search.png" alt="" />
              <input
                type="text"
                placeholder="Search tutor / Subject / Phone Number"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>
            <button onClick={handleSubmit}>Search...</button>
          </div>
        </div>
        <div className="right">
        </div>
      </div>
      
    </div>
  );
}

