import React from "react";
import "./GigCard.css";

const GigCard = ({ item }) => {
  return (
    <div className="gigCard">
      <div className="info">
      <div className="name-and-id"><p><strong>Teacher Name:</strong> {item.title}</p>
      <p><strong>ID:</strong> {item.gti}</p>
      </div>
      <p><strong>Category:</strong> {item.cat}</p>
      <p><strong>Short Description:</strong> {item.shortDesc}</p>
      <p><strong>Description:</strong> {item.desc}</p>
      <p><strong>Skills/Subjects:</strong> {item.features.join(", ")}</p>
      <p><strong>Qualification:</strong> {item.qualification}</p>
      <p><strong>Work Experience:</strong> {item.workExperience} years</p>
      <p><strong>Languages Known:</strong> {item.languagesKnown.join(", ")}</p>
      <p><strong>Current Occupation:</strong> {item.currentOccupation}</p>
      <p><strong>Specialities:</strong> {item.specialities.join(", ")}</p>
      <p><strong>Major Strengths:</strong> {item.majorStrengths.join(", ")}</p>
      <p><strong>Country:</strong> {item.country}</p>
      <div className="contact-info">
          <span><strong>Phone:</strong> {item.primaryphone} / {item.secondaryphone}</span>
          <span><strong>Email:</strong> {item.email}</span>
        </div>
      <p><strong>Avg. Price:</strong> ₹{item.price} per hour</p>

        
      </div>
    </div>
  );
};

export default GigCard;
